
  import Events from '@/events/Events';
  export default {
    name: 'JobCard',
    components: {
      JobCardBadges: () => import('@/components/elements/JobCardBadges'),
      FavorJobPostingButton: () => import('@/components/FavorJobPostingButton'),
      RegisterAndApplyButton: () =>
        import('@/components/RegisterAndApplyButton'),
      OrganizationLogo: () => import('@/components/elements/OrganizationLogo'),
      RichText: () => import('@/components/elements/RichText'),
    },
    props: {
      job: {
        type: Object,
        required: true,
      },
      application: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      favorite: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      renderedAsTopTeaserJobPosting: {
        type: Boolean,
        required: false,
        default: false,
      },
      renderedAsInstantTeaserJobPosting: {
        type: Boolean,
        required: false,
        default: false,
      },
      page: {
        type: Number,
        required: true,
      },
      position: {
        type: Number,
        required: true,
      },
      loggedIn: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    serverCacheKey(props) {
      // Deaktiviere das Caching, wenn der Benutzer eingeloggt ist
      if (props.loggedIn) {
        return false;
      }

      // Ansonsten generiere einen eindeutigen Cache-Schlüssel
      return (
        'job-card-' +
        props.job.id +
        '-' +
        props.renderedAsTopTeaserJobPosting +
        '-' +
        props.renderedAsInstantTeaserJobPosting
      );
    },
    data() {
      return {
        isApplicationVisible: false,
      };
    },
    computed: {
      hasFavorite() {
        return Object.keys(this.favorite).length > 0;
      },
    },
    methods: {
      clickWebhook() {
        this.$emitWithClientData(Events.JobDetailsClicked, {
          jobPostingId: this.job.id,
          type: 'JobListing',
          page: this.page,
          position: this.position,
          topJob: this.renderedAsTopTeaserJobPosting,
          instantTeaser: this.renderedAsInstantTeaserJobPosting,
        });
      },
      clickApplicationWebhook() {
        this.$emitWithClientData(Events.JobApplicationClicked, {
          jobPostingId: this.job.id,
          type: 'JobListing',
          page: this.page,
          position: this.position,
          topJob: this.renderedAsTopTeaserJobPosting,
          instantTeaser: this.renderedAsInstantTeaserJobPosting,
        });
      },
    },
  };
