
  import ScreenSize from '@/mixins/ScreenSize';
  import JobCard from '@/components/JobCard.vue';
  import JobListingBanner from '@/components/elements/JobListingBanner';

  export default {
    name: 'JobListing',
    components: {
      JobListingBanner,
      JobCard,
      JoblistingCta: () => import('@/components/JoblistingCta'),
    },
    mixins: [ScreenSize],
    props: {
      jobPostings: {
        type: Array,
        required: true,
      },
      topJobPositions: {
        type: Array,
        default: () => [],
      },
      instantTeaserPositions: {
        type: Array,
        default: () => [],
      },
      page: {
        type: Number,
        required: true,
      },
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        minJobPostingsForFirstBanner: 2,
        minJobPostingsForSecondBanner: 12,
        ctaPosition: 0,
      };
    },
    fetch() {
      this.calculateCtaPosition();
    },
    watch: {
      jobPostings() {
        this.calculateCtaPosition();
      },
    },
    methods: {
      calculateCtaPosition() {
        this.ctaPosition =
          this.jobPostings.length <= 3
            ? // if 3 or less: put it at the end
              this.jobPostings.length - 1
            : // else somewhere after the 3rd
              Math.floor(Math.random() * (this.jobPostings.length - 3)) + 3;
      },
    },
  };
