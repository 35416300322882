
  export default {
    name: 'JobListingBanner',
    props: {
      reviveZoneId: {
        type: String,
        required: true,
      },
      reviveId: {
        type: String,
        required: true,
      },
    },
    mounted: function () {
      // Neuladen der Banner (https://github.com/revive-adserver/revive-adserver/issues/725)
      // eslint-disable-next-line no-undef
      if (typeof reviveAsync !== 'undefined') {
        // eslint-disable-next-line no-undef
        reviveAsync[this.reviveId].apply(
          // eslint-disable-next-line no-undef
          reviveAsync[this.reviveId].detect()
        );
      }
    },
  };
